body {
  background-color: #272624;
  overflow-x: hidden;
  color: white;
}


.container {
  padding: 2em;
}

.logo-wrapper {
  color: white;
  padding-left: 30vh;
  cursor: default;
  overflow: hidden;
}


.logo-letter {
  font-family: 'Cairo', sans-serif;
  font-size: 6em;
  transition: 0.2s ease;
}

.logo-letter:hover {
  color: #fa32c8;
  text-shadow: 2px 2px 2px cyan;
}

.logo-main {
  font-size: 7em;
  text-shadow: 4px 4px 4px cyan;
  color: #fa32c8;
  transition: 0.2s ease;
}

.logo-main:hover {
  text-shadow: 4px 4px 4px red;
}
.fn-wrap {
  text-shadow: 3px 3px 3px red;
  transition: 0.2s ease-in-out;
  cursor: default;
}

.ln-wrap {
  text-shadow: 2px 2px 2px red;
  transition: 0.2s ease-in-out;
}


.sub-text {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 1.1em;
  opacity: 0.3;
  padding: 10px;
}



.sub-container {
  position: relative;
  padding: 30vh;
  padding-bottom: 2em;
}


.box-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 5em;
}

.box-content-html {
  position: relative;
  top: -50px;
  display: flex;
  padding: 1em;
  flex-direction: column;
  background: rgba( 255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  margin: 2px;
  width: 25%;
  height: 25%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  text-align: center;
}

.box-content {
  display: flex;
  padding: 1em;
  flex-direction: column;
  background: rgba( 255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  margin: 2px;
  width: 25%;
  height: 25%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  text-align: center;
  transform: translate3d(42px, -62px, -135px);
}


.box-content img {
  height: 100%;
  width: 100%;
}

.git-container {
  margin: auto;
  width: 80px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px 25px 25px 25px;
  transition: 0.4s ease-in-out;
}

.git-container:hover {
  background: white;
}




.threed-container {
  position: relative;
  width: 100%;
  height: 100vh;
}


.box {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  width: 400px;
  height: 400px;
  transform-style: preserve-3d;
}


.box div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(25deg) translate3d( -75px, -50px, 150px);
}


.box div span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0,0,0, .1);
  background: #ccc;
}

.box div span:nth-child(1){
  transform: rotateX(0deg) translate3d(0, 0, 200px);
}

.box div span:nth-child(2){
  transform: rotateY(90deg) translate3d(0, 0, -200px);
  transform: translateX(0px);
  background: blue;
  width: 14px;
}

.box div span:nth-child(3){
  transform: rotateX(90deg) translate3d(0, 0, 200px);
  background: red;
  width: 14px;
}

@media screen and (max-width: 960px) {


  .container{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }


  .logo-wrapper {
    padding: 5px;
  }


  .logo-letter {
    font-size: 4em;
  }

  .logo-main {
    font-size: 4em;
  }


  .sub-container {
    padding: 0;
    margin: 0;
  }


  .box-wrapper {
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .box-content {
    width: 21em;
  }

  .box-content-html {
    width: 21em;
    top: 0;
  }
}
