.writing-box {
  text-align: center;
  padding: 1em;
  font-size: 1.2em;
  border: 2px solid white;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  margin: 5px;
}


.writing-wrapper {
  display: flex;
  width: 90%;
}

.writing-wrapper-two {
  display: flex;
  flex-direction: column;
  width: 90%;
}


section {
  padding-left: 30vh;
}


@media screen and (max-width: 960px) {


  section {
    padding: 0;
  }

  .writing-wrapper {
    width: 100%;
    flex-direction: column;
  }

  .writing-wrapper-two {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

}
